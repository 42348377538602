import React from "react";
import {graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout"
import Seo from "../components/seo"
import imgSrc from '../images/img-selection.jpg';
import LangSelector from "../components/LangSelector";
import bgShadowSrc from '../images/bg-shadow.jpg';

const DATA = [
  {
    id: 'a',
    path: 'M1618,778.33v38l-116.67-24.67v-13.33l-38.67-16v-180h65.33v-48h90v48l39.33,1.33-20.67,196-18.67-1.33Z',
    transform: '',
    point: 'translate(1500 580)',
    x: -11,
    y: 10,
  },
   {
    id: 'b',
    path: 'M1289.5,592l20.5,92-20.5,5.5,25,96-136.5,23-20-23-41.5-174,83.5-19.5-4-16.5,33.5-6.5,4.5,16,25.2-.6,5.8,11.6,24.5-4Z',
    transform: '',
    point: 'translate(1138 600)',
    x: -10,
    y: 10,
  },
  {
    id: 'c',
    path: 'M894.03,589.62l3.47-15.62,32.5,5-3,17.5,46,9v12.5l58,13-19.5,104.5-61.5-12-15,77-30.5-5-4,18-37.5-5,4-22-60-15.5,36-187,8-2.5,43.03,8.12Z',
    transform: '',
    point: 'translate(860 580)',
    x: -11,
    y: 10,
  },
  {
    id: 'd',
    path: 'M629.5,588l4,18,47.5-9,22,101.5-44,10.5,11.5,55-30,23,6.5,21.5-60,12-4.5-18.5-70,14-30-195.5,93-21.5-6-18.5,33.38-5.83,3.12,18,23.5-4.67Z',
    transform: '',
    point: 'translate(504 610)',
    x: -11,
    y: 10,
  },
  {
    id: 'e',
    path: 'M241.5,584.5l18,3.5,3.5-11,43,5.5-3,14.5,60.5,13-3,19.5,45.5,7-19,90-44-8.5-14.5,70-51,20.5-113-29.5,12-187,65-7.5Z',
    transform: '',
    point: 'translate(190 600)',
    x: -10,
    y: 10,
  },
  {
    id: 'f',
    path: 'M259.5,105l117-38.5,62,49,12.5,62.5,37.5-6.5,21.5,96-37.5,6.5,5.5,19.5-122,27.5-33.5-14-63-202Z',
    transform: '',
    point: 'translate(280 100)',
    x: -10,
    y: 10,
  },
  {
    id: 'g',
    path: 'M644.5,84l75,10,4.5-10,40,10,2.5,6.5,15,34-11,51.5,11,3-2.5,13,42.01,9.95-22.51,96.55-53-11-2,15-50.5-13.5-3.5,13.5-36.5-5,1-16.5-28.5-3-32.75-25,51.75-179Z',
    transform: '',
    point: 'translate(660 90)',
    x: -11,
    y: 10,
  },
  {
    id: 'h',
    path: 'M926,98l131-37.5,15,74.5,2.5,28,21.5-6.5,23.5,105-23.5,4.5-8.5,21.5-8.5,2.5,1,10.5-34,7.5-4-12-74,16.5-42-176v-38.5Z',
    transform: '',
    point: 'translate(943 90)',
    x: -11,
    y: 10,
  },
  {
    id: 'i',
    path: 'M1288.5,76.5l135,17.5-25.5,185-42,28-35.5-8.5-2,8.5-37.5-5.5,2.5-12-47-7.5,32-178.5,20-27Z',
    transform: '',
    point: 'translate(1290 90)',
    x: -6,
    y: 10,
  }
  // {
  //   id: 'a',
  //   path: 'M21.46,213.208,218.857,0,499.163,29.494,415.3,212.936l1.723,1.32-.968,2.075,24.895,17.54-34.891,56.58,5.847,3.395-9.954,23.442L374.436,363.1l-37.523-17.722-3.09,3.538L255.931,466.225l-30.176-16.6L189.544,499.8,57.524,420.961l37.531-42.812-92.5-50.59Z',
  //   transform: 'translate(1007 473)',
  //   point: 'translate(1300 460)',
  //   x: -11,
  //   y: 10,
  // },
  // {
  //   id: 'b',
  //   path: 'M319.949,184.966V178.5l9.019-8.7-8.051-4.829L328,0,167.454,6.892.246,157.284,11.711,233.4s5.687-1.2,6.545,4.278,3.222,19.114,3.113,19.762-1.656,1.758-2.4,2.3c.32-.109,57.473,19.753,57.473,19.753L42.624,305.826l84.968,30.554,32.766-28.434,18.414,6.25,72.314-66.735,29.016,8.928,57.584-55.464-26.925-7.76Z',
  //   transform: 'translate(561 331.774)',
  //   point: 'translate(780 320)',
  //   x: -10,
  //   y: 10,
  // },
  // {
  //   id: 'c',
  //   path: 'M289.354,182.722,269.273,19.85l-59.837-1.115L0,103.66,32.68,221.11l38.979,18.347Z',
  //   transform: 'translate(325 242)',
  //   point: 'translate(540 240)',
  //   x: -11,
  //   y: 10,
  // },
  // {
  //   id: 'd',
  //   path: 'M108.956,193.6,28.938,171.033s-4.557-13.12-5.41-15.569-2.134-5.019-4.088-4.328l-.33.12L-2.115,95.742l157.08-82.725L232.373-.132l25.8,117.024.476.753L171.516,152.67l4.722,16.225Z',
  //   transform: 'translate(153 192.707)',
  //   point: 'translate(350 180)',
  //   x: -11,
  //   y: 10,
  // },
  // {
  //   id: 'e',
  //   path: 'M39.5,150.954,25.48,124.49-1.365,61.282,2.464,57.5,69,35.552l.48,1.545,39.488-12.963,9.271-4.55L181.107-1.793l20.455,69.782L90.435,126.309l5.948,15.523Z',
  //   transform: 'translate(60 162)',
  //   point: 'translate(230 150)',
  //   x: -10,
  //   y: 10,
  // },
  // {
  //   id: 'f',
  //   path: 'M167.02,35.462,165.3,9.906,133.026,6.73l-25.152,4.464-.707-7.383L70.1-.481-.117,12.653,14.688,102.2l54.606,16.3,89.8-30.029-4.687-51.335Z',
  //   transform: 'translate(477 137)',
  //   point: 'translate(630 120)',
  //   x: -10,
  //   y: 10,
  // },
  // {
  //   id: 'g',
  //   path: 'M8.729,91.593.632,6.359,53.006-.415,106.649,5.8v8.7l37.72-3.794,46.982,5.915-.671,83.586-114.832,22.1-14.821-4.5L18.5,105.79s-.807-8.364-1.034-12.582c-.16-3-.374-2.646-.843-3.025-.918-.58-.69-.4-2.812,0a5.067,5.067,0,0,1-.687.287C11.214,90.833,8.729,91.593,8.729,91.593Z',
  //   transform: 'translate(631 168)',
  //   point: 'translate(810 160)',
  //   x: -11,
  //   y: 10,
  // },
  // {
  //   id: 'h',
  //   path: 'M178.044,94.774,189.482,2.324l-21.941-.45L149.494,2.91V.538L123.162-.345.771,12.368-1.015,118.385l49.473-2.009-.593,10.155,62.724,18.242Z',
  //   transform: 'translate(841 215)',
  //   point: 'translate(1020 190)',
  //   x: -11,
  //   y: 10,
  // },
  // {
  //   id: 'i',
  //   path: 'M0,127.126,21.667,5.078,159,7.634l47.143,4.11v1.949H239l39.714,3.765L243.857,125.173,183.714,148.6,131,168.03,19.286,135.887a56.419,56.419,0,0,0,3.732-12.465c.476-3.122.7-5.821.161-7.159-2.115-1.75-4.943.449-10.651,3.624C5.852,123.59,0,127.126,0,127.126Z',
  //   transform: 'translate(1093 268.255)',
  //   point: 'translate(1350 260)',
  //   x: -6,
  //   y: 10,
  // }
];

const Path = styled.path``;

const Picker = styled.div`
  position: relative;
  /* display: none; */
  /* background-color: ${({theme}) => theme.colors.primary}; */

  /* &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #303030;
    opacity: 0.3;
    z-index: 1;
  } */

  svg {
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  a {
    text-decoration: none;
    z-index: 2;
  }

  .house-letter {
    font-weight: bold;
    opacity: 0;
    transition: all .5s ease;
    /* stroke: #C63922;
    stroke-width: 0px; */

    circle {
      fill: #ff940a;
    }

    text {
      stroke-width: 0px;
      font-size: 30px;
      fill: #fff;
    }
  }

  a:hover {
    text-decoration: none;
    ${Path} {
      /* opacity: 0.65; */
      opacity: 0.75;
    }

    .house-letter {
      opacity: 0.9;
    }
  }


  ${Path} {
    display: none;
    opacity: 0;
    /* fill: ${({theme}) => theme.colors.secondary}; */
    fill: #eaa548;
    cursor: pointer;
    transition: all .5s ease;

    &.selected {
      opacity: 0.5;
    }

    @media(min-width: 768px) {
      display: inline;
    }
  }

  ${Path}:hover {
    opacity: 0.6;

    &.selected {
      opacity: 0.5;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  @media(min-width: 992px) {
    display: block;
  }
`;

const MobileVersion = styled.div`
  padding: 70px 0;

  a {
    text-decoration: none;
    display: block;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.875rem;
    padding: 0.5rem 0.875rem;
    color:  ${({ isActive }) => isActive ? '#fff' : '#ff940a'};
    border: 2px solid #ff940a;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${({ isActive }) => isActive ? '#ff940a' : 'transparent'};
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
    background-color: #ff940a;
    color: #fff;
  }

  @media(min-width: 1200px) {
    display: none;
  }
`;

const Section = styled.div`
  position: relative;
  background-color: #fff;
  background-image: url(${bgShadowSrc});
  background-repeat: repeat-y;
  background-position: top left;
  /* padding: 40px 70px 110px; */


  @media(min-width: 1200px) {
    min-height: calc(100vh - 52px);
  }
`;

const Content = styled.div`
`;

const MobileSubtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: #303030;
  margin-bottom: 1.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 60px;
    height: 5px;
    background-color: #ff940a;
    transform: translate(-50%,-50%);
  }

  @media(min-width: 768px) {
    font-size: 1.875rem;
    line-height: 3rem;
  }
`;

const Subtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 3.375rem;
  line-height: 3rem;
  text-align: center;
  color: #fefefe;
  margin-bottom: 1.5rem;
  position: relative;
`;

const LeftSubtitle = styled(Subtitle)`
  display: none;
  text-align: left;
  position: absolute;
  bottom: 30px;
  left: 70px;
  text-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
  z-index: 2;
  margin-bottom: 0;

  @media(min-width: 1200px) {
    display: block;
  }

  @media(min-width: 1400px) {
    bottom: 70px;
  }
`;

function HoseSelectPage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('seo.house_selection.title')} />
      <Content>
        <Section>
          <LangSelector />
          <Picker>
            <LeftSubtitle as="h1">{t('house_selection.title')}</LeftSubtitle>
            {/* <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1609 1006"> */}
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1920 1080">
              <image href={imgSrc}></image>
                {
                  DATA.map(({id, path, transform, point, x, y}) => (
                    <Link key={id} to={`/dum-${id}`}>
                      <Path d={path} transform={transform}/>
                      <g transform={point} className="house-letter">
                        <g className="response-scale">
                            <circle cx="0" cy="0" r="30"></circle>
                            <circle cx="0" cy="0" r="30"></circle>
                            <text x={x} y={y}>{id.toUpperCase()}</text>
                        </g>
                      </g>
                    </Link>
                  ))
                }
            </svg>
          </Picker>
          <MobileVersion>
            <MobileSubtitle as="h1">{t('house_selection.title')}</MobileSubtitle>
            {
              DATA.map(({id}) => (
                <Link key={id} to={`/dum-${id}`}>
                  {t('house_selection.subtitle', {id})}
                </Link>
              ))
            }
          </MobileVersion>
        </Section>
      </Content>
    </Layout>
  )
}

export default HoseSelectPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
